* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Montserrat", sans-serif !important;
}

body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif; */
  /* font-family: "Montserrat", sans-serif !important; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.removeScrollBar {
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
}

.removeScrollBar::-webkit-scrollbar {
  width: 0px; /* Remove scrollbar space */
  background: transparent; /* Optional: just make scrollbar invisible */
}

p::-webkit-scrollbar {
  width: 0px; /* Remove scrollbar space */
  background: transparent; /* Optional: just make scrollbar invisible */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.errorMsg {
  font-size: 12px;
  color: red;
}

.infoMsg {
  font-size: 12px;
  color: #1568bf;
}

/* react tooltip css override */
.__react_component_tooltip {
  background: #f7f7f7 !important;
  opacity: 1 !important;
}

.__react_component_tooltip.show {
  opacity: 1;
  /* margin-top: 0px; */
  margin-left: -48px;
  visibility: visible;
}

.__react_component_tooltip.place-bottom:after {
  margin-left: 41px;
  top: -7px !important;
}

.__react_component_tooltip.place-left::after {
  border-left: 8px solid #f7f7f7 !important;
}

.__react_component_tooltip.place-right::after {
  border-right: 8px solid #f7f7f7 !important;
}

.__react_component_tooltip.place-top::after {
  border-top: 8px solid #f7f7f7 !important;
}

.__react_component_tooltip.place-bottom::after {
  border-bottom: 8px solid #f7f7f7 !important;
}

.__react_component_tooltip.place-bottom:before {
  border-left: 9px solid transparent;
  border-right: 9px solid transparent;
}

.__react_component_tooltip.type-dark.border.place-bottom:before {
  border-bottom: 8px solid #1568bf !important;
}

.swal-footer, .swal-text {
  text-align: center;
}

.swal-button--confirm {
  color: #fff;
  background: #1568bf;
  border: 1px solid #1568bf;
  width: 100px;
}

.swal-button--cancel {
  color: #1568bf;
  background: #e8e8e8;
  border: 1px solid #e8e8e8;
  width: 100px;
}

.swal-button--confirm:hover {
  background: #1568bf !important;
  border: 1px solid #1568bf !important;
}

/* end of react tooltip css override */

.infinite-scroll-component{
  padding:5px;
}

.ForwardRef-searchContainer-7 {
  width: 100% !important;
}